import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
declare let $: any;

@Component({
  selector: 'app-add-item1',
  templateUrl: './add-item1.component.html',
  styleUrls: ['./add-item1.component.scss']
})
export class AddItem1Component implements OnInit {

  add:any={};
  Items=[];
  add1=[];
  date="";
  public btndisable:boolean=false;
  constructor(public datePipe:DatePipe,public appservice:AppService,private toastr: ToastrService, private http: HttpClient) {


    if(this.appservice.isEdit)
    {
      this.add=appservice.Edit_Row;
      this.get_server_address();
    }
    else
    {
      this.clear();
      this.add.ID="0";
      this.get_Item_Code();

   
    }
  

   }


   get_server_address()
   {
    this.server=this.appservice.Server_URL+"Api/Master/UploadItemImage?ID="+this.add.ID+"&Company="+this.appservice.Company+"&Name="+this.add.Item_Code+"&Description="+this.add.Description;
    this.server1=this.appservice.Server_URL+"Api/Master/UploadItemImage?ID="+this.add.ID+"&Company="+this.appservice.Company+"&Name="+this.add.Item_Code+"_1"+"&Description="+this.add.Description;
    this.server2=this.appservice.Server_URL+"Api/Master/UploadItemImage?ID="+this.add.ID+"&Company="+this.appservice.Company+"&Name="+this.add.Item_Code+"_2"+"&Description="+this.add.Description;
    this.server3=this.appservice.Server_URL+"Api/Master/UploadItemImage?ID="+this.add.ID+"&Company="+this.appservice.Company+"&Name="+this.add.Item_Code+"_3"+"&Description="+this.add.Description;
    this.server4=this.appservice.Server_URL+"Api/Master/UploadItemImage?ID="+this.add.ID+"&Company="+this.appservice.Company+"&Name="+this.add.Item_Code+"_4"+"&Description="+this.add.Description;
  
   }
   server1;
   server2;
   server3;
   server4;
   server5;

   get_Item_Master() {

   
    this.appservice.loading = true;
    this.appservice.isload = true;
    this.appservice.Item_Master_Rows = [];
    this.appservice.get("Api/Master/get_Item_Master?Order_by=" + this.appservice.Item_Orderby_Name).subscribe((res: any) => {
      this.appservice.Item_Master_Rows = JSON.parse(res).record;

      this.appservice.Perment_Item_Master_Row = JSON.parse(res).record;
      this.appservice.Reset_Item_Master_Row = JSON.parse(res).record;
      
     
     
      

      this.appservice.loading = false;
      this.appservice.isload = false;


      try {
        this.appservice.Item_Row_Cout = this.appservice.Item_Master_Rows.length;

      } catch { }

        this.appservice.Item_Category = [...new Set(this.appservice.Item_Master_Rows.filter(e=>e.Item_Group=='1').map(item => item.Category))];

     
    });
  }
  
   clear()
   {
     
    this.appservice.get_fields_of('Item_Master').forEach((activity) => {
      if(activity.Default_Value=="T_Date")
      {
        this.add[activity.Field]=this.appservice.T_Date;
      }
      
      else{
      this.add[activity.Field]=activity.Default_Value;
      }
    });

    
   }

   get_Item_Code()
   {
    
      if(this.appservice.isEdit==false)
      {
      this.appservice.getc("Api/Master/get_Item_Code").subscribe((res: any) => { 
        this.add.Item_Code=res;
        this.get_server_address();
      }); 
    }
      
   }

   
  get_image(data)
  { 
     var timeStamp = (new Date()).getTime();
if(data=="0")
return this.appservice.Server_URL+"image/C"+this.appservice.Company+"/"+this.add.Item_Code+".png?data="+timeStamp;
     
    return this.appservice.Server_URL+"image/C"+this.appservice.Company+"/"+this.add.Item_Code+"_"+data+".png?data="+timeStamp;
  }

   Auto_Code:boolean=false;
  
   uploadedFiles: any[] = [];

   server;
   onUpload(event) {
       for(let file of event.files) {
           this.uploadedFiles.push(file);
       }
 
       this.toastr.success("File Uploaded  Success", 'Msg');
       
   }
 
   addData(f)
   {
    
      this.add.Company=this.appservice.Company;
      this.add.Company_ID=this.appservice.Company;
      this.add.Created_by=this.appservice.CREATED_BY;
      this.add.Table_Name="Item_Master";
   
      if (f.invalid === false)
      {
  
      this.btndisable=true;
            this.http.post(this.appservice.Server_URL + 'api/Master/Post_Data', this.add, { headers: this.appservice.headers })
              .subscribe(
                (val: string) => {
                  this.btndisable=false;

                  if(val=="True")
                  { this.toastr.success("Details Salved Success", 'Msg');

                  if(this.appservice.isEdit)
                  {
                    this.appservice.back();

                  }
                  else
                  {
                  this.appservice.isEdit=false;
                  this.appservice.get_Item_Master();
                  $(".cls_0").focus();
                  f.submitted=false;
                  this.clear();
                  }
                }
                else{
                  this.toastr.error(val, "Error", { timeOut: 3000 });
                }
                },
                response => {
                  this.toastr.error('Error ', response, {
                    timeOut: 3000
                  });
                  
                });
     
    }
   }

  ngOnInit(): void {
  }

}
