import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard/dashboard.component';
import { AnalyticsComponent } from './components/pages/dashboard/analytics/analytics.component';
import { EcommerceComponent } from './components/pages/dashboard/ecommerce/ecommerce.component';
import { AuthenticationComponent } from './components/pages/authentication/authentication/authentication.component';
import { LoginComponent } from './components/pages/authentication/login/login.component';
import { LoginWithImageComponent } from './components/pages/authentication/login-with-image/login-with-image.component';
import { RegisterComponent } from './components/pages/authentication/register/register.component';
import { RegisterWithImageComponent } from './components/pages/authentication/register-with-image/register-with-image.component';
import { ForgotPasswordComponent } from './components/pages/authentication/forgot-password/forgot-password.component';
import { ForgotPasswordWithImageComponent } from './components/pages/authentication/forgot-password-with-image/forgot-password-with-image.component';
import { ResetPasswordComponent } from './components/pages/authentication/reset-password/reset-password.component';
import { ResetPasswordWithImageComponent } from './components/pages/authentication/reset-password-with-image/reset-password-with-image.component';
import { ReferenceDetailsComponent } from './master/reference-details/reference-details.component';
import { ReferencMasterComponent } from './master/referenc-master/referenc-master.component';
import {FileUploadModule} from 'primeng/fileupload';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CustomerDetailsComponent } from './master/customer-details/customer-details.component';
import { CustomersComponent } from './master/customers/customers.component';
import { AddBankComponent } from './master/add-bank/add-bank.component';
import { BankDetailsComponent } from './master/bank-details/bank-details.component';
import { AddItemComponent } from './master/add-item/add-item.component';
import { ItemMasterComponent } from './master/item-master/item-master.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { UserMasterComponent } from './master/user-master/user-master.component';
import { AddUserComponent } from './master/add-user/add-user.component';
import { MyDatePickerModule } from 'mydatepicker';
import { OrderEntryComponent } from './order-entry/order-entry.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { BannerUploadComponent } from './banner-upload/banner-upload.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { CategoryImageComponent } from './category-image/category-image.component';
import { DocumentUploadDetailsComponent } from './document-upload-details/document-upload-details.component';

import { RoleRolesPermissionsComponent } from './role-roles-permissions/role-roles-permissions.component';
import { SearchCustomerComponent } from './search-customer/search-customer.component';
import { AddProductComponent } from './add-product/add-product.component';
import { SalesEntryComponent } from './sales-entry/sales-entry.component';
import { PaymodeSelectComponent } from './paymode-select/paymode-select.component';
import { ViewOrderDetailsComponent } from './view-order-details/view-order-details.component';
import { UpdateCategoryComponent } from './update-category/update-category.component';
import { UpdateCompanyDetailsComponent } from './update-company-details/update-company-details.component';
import { AddProduct1Component } from './add-product1/add-product1.component';
import { GenerateBillsComponent } from './generate-bills/generate-bills.component';
import { HomeCollectionComponent } from './home-collection/home-collection.component';
import { NotificatonComponent } from './notificaton/notificaton.component';
import { AddItem1Component } from './master/add-item1/add-item1.component';
import { AddCustomerAutoComponent } from './master/add-customer-auto/add-customer-auto.component';
import { RoleMasterComponent } from './role-master/role-master.component';
import { SettingDashboardComponent } from './setting-dashboard/setting-dashboard.component';
import { CutomerSearchComponent } from './cutomer-search/cutomer-search.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { DeletedOrderDetailsComponent } from './report/deleted-order-details/deleted-order-details.component';
import { DeletedItemDetailsComponent } from './report/deleted-item-details/deleted-item-details.component';
import { TemplateComponent } from './setting/template/template.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { B2CReportComponent } from './report/GST-Report/b2-c-report/b2-c-report.component';
import { BillwiseProfitComponent } from './report/Profit-Report/billwise-profit/billwise-profit.component';
import { ItemwiseProfitComponent } from './report/Profit-Report/itemwise-profit/itemwise-profit.component';
import { CustomerwiseProfitComponent } from './report/Profit-Report/customerwise-profit/customerwise-profit.component';
import { OverAllProfitComponent } from './report/Profit-Report/over-all-profit/over-all-profit.component';
import { SalesDashboardTwoComponent } from './sales-dashboard-two/sales-dashboard-two.component';
import { ViewOrderDetails1Component } from './view-order-details1/view-order-details1.component';
import { InvalidLicenceComponent } from './invalid-licence/invalid-licence.component';
import { AddItemsComponent } from './add-items/add-items.component';
import { UserwiseSalesComponent } from './report/userwise-sales/userwise-sales.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { DefaultDashboardComponent } from './default-dashboard/default-dashboard.component';
import { SalesVsRMComponent } from './report/sales-vs-rm/sales-vs-rm.component';
import { ItemwiseSalesVsRMComponent } from './report/itemwise-sales-vs-rm/itemwise-sales-vs-rm.component';
import { SaleswiseProfitComponent } from './saleswise-profit/saleswise-profit.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { Analysis1Component } from './analysis1/analysis1.component';
import { Analysis2Component } from './analysis2/analysis2.component';
import { ReferenceGroupComponent } from './reference-group/reference-group.component';
import { AddReferenceGroupComponent } from './add-reference-group/add-reference-group.component';
import { SalesReturnDetailsComponent } from './sales-return-details/sales-return-details.component';
import { SalesReturnApprovedComponent } from './sales-return-approved/sales-return-approved.component';
import { CategorywiseProfitComponent } from './report/categorywise-profit/categorywise-profit.component';
import { QuizDetailsComponent } from './report/quiz-details/quiz-details.component';
import { EditorModule } from 'primeng/editor';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    AnalyticsComponent,
    EcommerceComponent,
    AuthenticationComponent,
    LoginComponent,
    LoginWithImageComponent,
    RegisterComponent,
    RegisterWithImageComponent,
    ForgotPasswordComponent,
    ForgotPasswordWithImageComponent,
    ResetPasswordComponent,
    ResetPasswordWithImageComponent,
    ReferenceDetailsComponent,
    ReferencMasterComponent,
    CustomerDetailsComponent,
    CustomersComponent,
    AddBankComponent,
    BankDetailsComponent,
    AddItemComponent,
    ItemMasterComponent,
    UserMasterComponent,
    AddUserComponent,
    OrderEntryComponent,
    OrderDetailsComponent,
    ViewOrderDetailsComponent,
    BannerUploadComponent,
    DocumentUploadComponent,
    CategoryImageComponent,
    DocumentUploadDetailsComponent,
    UpdateCategoryComponent,
    UpdateCompanyDetailsComponent,
    RoleRolesPermissionsComponent,
    SearchCustomerComponent,
    AddProductComponent,
    SalesEntryComponent,
    PaymodeSelectComponent,
    AddProduct1Component,
    GenerateBillsComponent,
    HomeCollectionComponent,
    NotificatonComponent,
    AddItem1Component,
    AddCustomerAutoComponent,
    RoleMasterComponent,
    SettingDashboardComponent,
    CutomerSearchComponent,
    ChangePasswordComponent,
    UserProfileComponent,
    DeletedOrderDetailsComponent,
    DeletedItemDetailsComponent,
    TemplateComponent,
    SalesDashboardComponent,
    B2CReportComponent,
    BillwiseProfitComponent,
    ItemwiseProfitComponent,
    CustomerwiseProfitComponent,
    OverAllProfitComponent,
    SalesDashboardTwoComponent,
    ViewOrderDetails1Component,
    InvalidLicenceComponent,
    AddItemsComponent,
    UserwiseSalesComponent,
    AddRoleComponent,
    DefaultDashboardComponent,
    SalesVsRMComponent,
    ItemwiseSalesVsRMComponent,
    SaleswiseProfitComponent,
    AnalysisComponent,
    Analysis1Component,
    Analysis2Component,
    ReferenceGroupComponent,
    AddReferenceGroupComponent,
    SalesReturnDetailsComponent,
    SalesReturnApprovedComponent,
    CategorywiseProfitComponent,
    QuizDetailsComponent,
   
    
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DropdownModule,
    RouterModule ,
    FormsModule ,
    ReactiveFormsModule,
    EditorModule,
    HttpClientModule,
    TableModule,
    FileUploadModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    }),
    
    ConfirmDialogModule,
    MyDatePickerModule,
   
  ],

 
  providers: [DatePipe,
    ConfirmationService,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


 export  interface Order_DB_ {
  Pending: number;
  Delivery: number;
  T_Pending: number;
  T_Orders: number;
  Y_Orders: number;
  Y_Pending: number;
  Y_Delivery: number
}

