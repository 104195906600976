<div class="row">
  <div class="col-md-12 nopad">
      <div class="card content ">

          <div *ngIf="appservice.openModal!=true" class=" Head ">



              <div class="card-header  d-flex justify-content-between align-items-center">
                  <h3>Item</h3>
                  <div class="dropdown">

                    <a (click)="appservice.back()" class="x">
                          <b>X</b>
                      </a>

                  </div>
              </div>

          </div>

          <div class="content_body">



              <form #addForm="ngForm" (ngSubmit)="addData(addForm)" (keydown.enter)="$event.preventDefault()"
                  novalidate>

                  <div class="row">
                      <div class="col-sm-6">

                          <div class="form-table table-responsive">
                              <table class="table table-bordered">
                                  <tbody>
                                      <tr class="hidden">
                                          <td>ID <span class="text-danger">*</span></td>
                                          <td><input class="form-control" type="text" [(ngModel)]="add.ID" name="ID"
                                                  #ID="ngModel" required></td>
                                      </tr>

                                      <tr>
                                        <td>Item Code<span class="text-danger">*</span></td>
                                        <td>
                                          <input class="form-control" type="text" [(ngModel)]="add.Item_Code" readonly name="Item_Code" #Item_Code="ngModel">
                                          <div *ngIf="addForm.submitted && Item_Code.invalid" class="invalid-feedback">
                                            <div class="error"> Item Code  Should not entry</div>
                                        </div>
                                        </td>
                                      </tr>

                                      <tr *ngFor="let data of  appservice.get_pos_field('Item_Master','1TL')">
                                          <td>{{data.Name}}  <span *ngIf="data.Validate=='True'" class="text-danger">*</span></td>

                                          <td>


                                            <select  *ngIf="(data.Type=='Select' && data.Field=='Item_Group')" class="select2-option form-control"  aria-hidden="true"
                                               required="data.Validate=='True'" [(ngModel)]="add[data.Field]"
                                                  name="{{data.Field}}" #{{data.Field}}="ngModel">
                                                 <option *ngFor="let s of appservice.Item_Group" value={{s.ID}}>
                                                     {{s.Item_Name}}
                                                 </option>
                                               </select>


                                              <select  *ngIf="(data.Type=='Select' && data.Field!='Item_Group')" class="select2-option form-control"  aria-hidden="true"
                                               required="data.Validate=='True'" [(ngModel)]="add[data.Field]"
                                                  name="{{data.Field}}" #{{data.Field}}="ngModel">
                                                 <option *ngFor="let row of appservice.get_ref(data.Options)" value={{row[data.Store_Value]}}>
                                                     {{row.label}}
                                                 </option>
                                               </select>

                                              
                                              <input *ngIf="data.Type!='Select'" class="form-control cls_0"   [readonly]="data.IsEdit=='False'"  required="data.Validate=='True'" type="{{data.Type}}" [(ngModel)]="add[data.Field]"
                                                  name="{{data.Field}}" #{{data.Field}}="ngModel"   >
                                              
                                                  <div *ngIf='addForm.submitted && (data.Validate=="True") && (add[data.Field]=="")' class="invalid-feedback d-block">
                                                      <div class="error"> {{data.Name}} Should not entry</div>
                                                  </div>  
                                              </td>
                                      </tr>



                                  </tbody>
                              </table>


                              <div class="invoice-btn-box text-right " style="padding-bottom:20px">

                                  <button class="btn btn-info rounded-pil" [disabled]="btndisable" type="submit">


                                      <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm"
                                          role="status" aria-hidden="true"></span>
                                      <i *ngIf="btndisable==false" class='bx bx-paper-plane'></i>
                                      Save </button> &nbsp;&nbsp;
                                  <button   *ngIf="appservice.openModal!=true"  class="btn btn-secondary rounded-pil " type="button"
                                      (click)="appservice.back()">Cancel</button> &nbsp;&nbsp;
                              </div>

                          </div>
                      </div>

                      <div class="col-md-6">
                        
                        <tr *ngIf="appservice.img_visible" >
                            
                            <td colspan="2">
                              <p-fileUpload 
                              mode="basic"  name="myfile[]"  chooseLabel="Upload Front Image" accept="image/*"
                              
                              url="{{server}}" (onUpload)="onUpload($event)"
                                multiple="multiple" maxFileSize="1000000">
                                <ng-template pTemplate="content">
                                  <ul *ngIf="uploadedFiles.length">
                                    <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                                  </ul>
                                </ng-template>
                              </p-fileUpload>

                              <img class="icon-img" style="width: 150px;"
                              [src]="get_image(0)"
                              alt="First slide">
    
                            </td>
                          </tr>
                      
                      </div>


                
                  </div>
              </form>




              <div class="row">
                <div class="col-md-12">
      
                 
      
                  <p-editor [(ngModel)]="add.Full_Desc" name="Full_Desc" #Full_Desc="ngModel" [style]="{'height':'320px'}">
      
                  </p-editor>
      
                  {{add.Desc}}
                  <table class="table">
                    <tr>
      
                      <td>
                      <p-fileUpload mode="basic"  name="myfile[]"  chooseLabel="Image1" accept="image/*" maxFileSize="1000000" url="{{server1}}" (onUpload)="onUpload($event)" ></p-fileUpload>
      
                      
                      <img class="icon-img" style="width: 150px;"
                      [src]=get_image(1) >
                      </td>
      
                      <td>
                        <p-fileUpload mode="basic"  name="myfile[]"  chooseLabel="Image2" accept="image/*" maxFileSize="1000000" url="{{server2}}" (onUpload)="onUpload($event)" ></p-fileUpload>
        
                        
        
        
                        <img class="icon-img" style="width: 150px;"
                        [src]=get_image(2)>
                        </td>
      
      
                       
                      <td>
                        <p-fileUpload mode="basic"  name="myfile[]"  chooseLabel="Image3" accept="image/*" maxFileSize="1000000" url="{{server3}}" (onUpload)="onUpload($event)" ></p-fileUpload>
        
                        
        
        
                        <img class="icon-img" style="width: 150px;"
                        [src]=get_image(3)>
                        </td>
      
      
                             
                      <td>
                        <p-fileUpload mode="basic"  name="myfile[]"  chooseLabel="Image4" accept="image/*" maxFileSize="1000000" url="{{server4}}" (onUpload)="onUpload($event)" ></p-fileUpload>
        
                        <img class="icon-img" style="width: 150px;"
                          [src]=get_image(4) >
                        </td>
      
                        
                    </tr>
                  </table>
      
                </div>
              </div>
          </div>
      </div>
  </div>