import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quiz-details',
  templateUrl: './quiz-details.component.html',
  styleUrls: ['./quiz-details.component.scss']
})
export class QuizDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
